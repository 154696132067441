import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Roles(content) {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="roles"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            mb: 5
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: { xs: ".5em", md: 0 },
              textAlign: { xs: "center", md: "inherit" }
            }}
          >
            {t('minecraft.roles')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
            width: '100%',
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: { xs: "100%", md: "50%" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10
              }}
            >
              <img
                src={data.image}
                alt="ЄУК"
                style={{
                  width: "100%",
                  borderRadius: 10
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexBasis: { xs: "100%", md: "50%" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              ml: { xs: 0, md: 5 },
            }}
          >
            <Box
              sx={{
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 12, sm: 14 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                },
                "& a": {
                  color: '#3CADFF !important',
                  textDecoration: 'none !important'
                }
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Box>
        </Box>
      </Container>
    </Container>
  );
}

export default Roles;
