import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import { Grid } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Bosses(content) {
  const [data, setData] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setData(content.content || {});
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="bosses"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        mt: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#101010",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            mb: 5
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: { xs: ".5em", md: 0 },
              textAlign: { xs: "center", md: "inherit" }
            }}
          >
            {t('minecraft.bosses')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            width: '100%',
            justifyContent: 'center',
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: { xs: "100%", md: "50%" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                mb: 1,
              }}
            >
              <img
                src={data.image}
                alt="ЄУК"
                style={{
                  width: "100%",
                  borderRadius: 10
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexBasis: { xs: "100%", md: "50%" },
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              ml: { xs: 0, md: 5 },
            }}
          >
            <Box
              sx={{
                color: "white",
                fontWeight: 400,
                letterSpacing: 1,
                fontSize: { xs: 12, sm: 14 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
                "& > p": {
                  marginBlockStart: 2.5,
                  marginBlockEnd: 2.5
                }
              }}
              dangerouslySetInnerHTML={{ __html: getContent(data, 'text', language) }}
            />
          </Box>
        </Box>
        <Accordion
          expanded={expanded === `panel-0`}
          onChange={handleChange(`panel-0`)}
          sx={{
            width: '100%',
            backgroundColor: "transparent",
            color: "white",
            my: "16px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls={`panel-0-content`}
            id={`panel-0-header`}
            sx={{
              border: "1px solid #4a4a4a",
              borderRadius: 1,
              my: 5,
              width: '100%',
              "& .MuiAccordionSummary-content": {
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center"
              }
            }}
          >
            <Typography
              variant="h1"
              component="h2"
              sx={{
                color: "white",
                fontWeight: 500,
                letterSpacing: 1,
                fontSize: { xs: 14, sm: 16 },
                lineHeight: 1.5,
                fontFamily: "Montserrat",
              }}
            >
              {t('minecraft.bosses')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              padding: "16px 16px 0 16px"
          }}
          >
            <Grid container spacing={1} sx={{ m: 0 }}>
              {(data.bosses || []).map((boss, i) => (
              <Grid
                item
                key={i}
                xs={12}
                md={6}
                sx={{
                  width: '100%',
                  position: 'relative',
                  borderRadius: 5,
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 5,
                    height: 500,
                    width: '100%',
                    mb: 1,
                  }}
                >
                  <img
                    src={boss.image}
                    alt="ЄУК"
                    style={{
                      position: 'relative',
                      objectFit: 'cover',
                      height: "100%",
                      width: '100%',
                      borderRadius: 10
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: 'column',
                    backgroundColor: '#24242C',
                    borderRadius: 5,
                    p: 2,
                  }}
                >
                  <Typography
                    component="h3"
                    paragraph
                    sx={{
                      fontSize: 18,
                      fontWeight: 700,
                      mb: 0,
                      color: "white"
                    }}
                  >
                    <b>{getContent(boss, 'name', language)}</b>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: 'column',
                      alignItems: "center",
                      color: "white",
                      fontWeight: 400,
                      width: '100%',
                      letterSpacing: 1,
                      fontSize: { xs: 12, sm: 14 },
                      lineHeight: 1.5,
                      fontFamily: "Montserrat",
                      "& > p": {
                        marginBlockStart: 0.5,
                        marginBlockEnd: 0.5
                      }
                    }}
                    dangerouslySetInnerHTML={{ __html: getContent(boss, 'text', language) }}
                  />
                </Box>
              </Grid>
            ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Container>
  );
}

export default Bosses;
