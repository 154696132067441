import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo from "../assets/logo.png";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation, useNavigate } from "react-router-dom";
import { Fab, Fade, useScrollTrigger } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateScrollToSectionId } from '../store/navigationReducer';
import { useTranslation } from 'react-i18next';

const pages = [{
  name: "header.advantages",
  key: "advantages",
  main: true,
  newPage: false,
  subMenu: false,
}, {
  name: "header.support",
  key: "support",
  main: true,
  newPage: false,
  subMenu: false,
}, {
  name: "header.discord",
  key: "discord",
  main: true,
  newPage: false,
  subMenu: false,
}, {
  name: "header.events",
  key: "events",
  main: true,
  newPage: false,
  subMenu: false,
}, {
  name: "header.interesting",
  key: "interesting",
  main: true,
  newPage: false,
  subMenu: false,
}, {
  name: "header.admin",
  key: "admin",
  main: true,
  newPage: false,
  subMenu: false,
}, {
  name: "header.feedback",
  key: "feedback",
  main: true,
  newPage: false,
  subMenu: false,
}, {
  name: "header.minecraft",
  key: "minecraft",
  main: false,
  newPage: true,
  subMenu: true,
}, {
  name: "header.faq",
  key: "faq",
  main: false,
  newPage: true,
  subMenu: false,
}];

const minecraftSections = [{
  name: "header.minecraft.connect",
  key: "connect",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.rules",
  key: "rules",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.team",
  key: "team",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.gallery",
  key: "gallery",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.roles",
  key: "roles",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.commands",
  key: "commands",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.bosses",
  key: "bosses",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.spawn",
  key: "spawn",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.decoration",
  key: "decoration",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.lands",
  key: "lands",
  main: false,
  newPage: false,
}, {
  name: "header.minecraft.brewery",
  key: "brewery",
  main: false,
  newPage: false,
}];

const subMenus = {
  '/minecraft': minecraftSections,
}

function ScrollHome(props) {
  const { children } = props;
  const navigate = useNavigate();

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
      navigate("/")
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 0,
        width: { xs: 30, md: 50 },
        height: { xs: 30, md: 50 },
        m: 1,
        mt: { xs: 2.3, md: 1 }
      }}
      onClick={handleClick}
    >
      {children}
    </Box>
  );
}

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );
    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: 'fixed', bottom: 100, right: 16, zIndex: 999,
          display: { xs: "none", sm: "initial" }
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function Header(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const [subMenu, setSubMenu] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElSubMenu, setAnchorElSubMenu] = React.useState(null);
  const scrollToSectionId = useSelector((state) => state.navigation.scrollToSectionId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setScrollToSectionId = (pageId) => {
    dispatch(updateScrollToSectionId(pageId));
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseSubMenu = () => {
    setAnchorElSubMenu(null);
  };

  const moveToSection = (pageId) => {
    const anchor = document.querySelector(`#${pageId}`);
    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
      handleCloseNavMenu();
      handleCloseSubMenu();
    }
  };

  const handleClick = (event, id, main, newPage) => {
    const targetClassList = event.target.classList;

    if (targetClassList.contains('MuiBackdrop-root')) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    if (main && location.pathname !== "/") {
      setScrollToSectionId(id);
      navigate("/");
    } else if (newPage && location.pathname !== `/${id}`) {
      navigate(`/${id}`);
    } else {
      setAnchorElSubMenu(event.currentTarget);
      moveToSection(id);
    }
  };

  React.useEffect(() => {
    setSubMenu(subMenus[location.pathname]);

    if (scrollToSectionId) {
      setTimeout(() => {
        moveToSection(scrollToSectionId);
        setScrollToSectionId(null)
      }, 1000);
    }
  }, [location]);

  return (
    <>
      <span style={{ visibility: "hidden" }} id="back-to-top-anchor"></span>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          padding: { xs: ".3em 1em", md: ".5em 2em", lg: "1.5em 3em" },
          boxShadow: "none",
          position: "fixed",
          visibility: "visible !important",
          transform: "none !important",
          backgroundColor: "#000000",
          zIndex: 999
        }}
      >
        <Container
          maxWidth="none"
          sx={{
            maxWidth: "1400px",
            padding: "0 !important",
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              maxWidth: "auto",
              display: "flex",
              width: "100%",
              alignItems: 'flex-start',
              justifyContent: "space-between"
            }}
          >
            <ScrollHome {...props}>
              <img
                src={logo}
                alt="ЄУК"
                style={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer"
                }}
              />
            </ScrollHome>

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "flex", md: 'none' },
                color: 'white',
                justifyContent: "space-around",
              }}
            >
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{
                  display: "block",
                  m: 0
                }}
              >
                <MenuIcon sx={{ fontSize: 40 }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                variant='menu'
                autoFocus={false}
                disableAutoFocusItem
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  flexGrow: 1,
                  display: { xs: 'block', md: 'none', color: "white" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.key}
                    onClick={(e) => handleClick(e, page.key, page.main, page.newPage)}
                    sx={{
                      display: "flex",
                    }}
                    aria-controls={subMenu && Boolean(anchorElSubMenu) ? `sub-menu-${page.key}` : undefined}
                    aria-haspopup={subMenu && Boolean(anchorElSubMenu) ? "true" : undefined}
                  >
                    {subMenu && Boolean(anchorElSubMenu) ? (
                      <Menu
                        id={`sub-menu-${page.key}`}
                        anchorEl={anchorElSubMenu}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        variant='menu'
                        autoFocus={false}
                        disableAutoFocusItem
                        open={Boolean(anchorElSubMenu)}
                        onClose={handleCloseSubMenu}
                        sx={{
                          marginLeft: "auto",
                          display: { xs: 'flex', md: 'none' }
                        }}
                      >
                        {subMenu.map((subPage) => (
                          <MenuItem
                            key={subPage.key}
                            onClick={(e) => handleClick(e, subPage.key, subPage.main, subPage.newPage)}
                          >
                            <Typography
                              textAlign="center"
                              sx={{
                                fontWeight: 500,
                                fontSize: 16,
                              }}
                            >
                              {t(subPage.name)}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    ) : null}
                    <Typography
                      textAlign="center"
                      sx={{
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      {t(page.name)}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                flexDirection: 'column',
                mt: '.9em',
                mx: { md: ".9em", lg: "2em" },
                display: { xs: 'none', md: 'flex' }
              }}
            >
              <Box sx={{
                display: 'flex',
                flexGrow: 1,
              }}>
                {pages.map((page) => (
                  <Button
                    key={page.key}
                    onClick={(e) => handleClick(e, page.key, page.main, page.newPage)}
                    sx={{
                      px: 2,
                      color: 'white',
                      display: "flex",
                      alignItems: 'flex-start',
                      textTransform: 'none',
                      fontWeight: 500,
                      width: 'fit-content',
                      minWidth: 'auto',
                      fontSize: { md: 15, lg: 16 },
                      borderRadius: 5,
                      "@media(max-width: 1300px)": {
                        px: 1.5,
                      },
                      "@media(max-width: 1200px)": {
                        px: 1,
                      },
                      "@media(max-width: 1000px)": {
                        px: 0.5,
                      },
                    }}
                  >
                    {t(page.name)}
                  </Button>
                ))}
              </Box>
              {subMenu ? (
                <Box
                  sx={{
                    flexGrow: 0,
                    justifyContent: "flex-end",
                    display: { xs: 'none', md: 'flex' },
                    pt: 1.5,
                  }}
                >
                  {subMenu.map((page) => (
                    <Button
                      key={page.key}
                      onClick={(e) => handleClick(e, page.key, page.main, page.newPage)}
                      sx={{
                        px: 2,
                        color: 'white',
                        display: "block",
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: 12,
                        borderRadius: 5,
                        width: 'fit-content',
                        minWidth: 'auto',
                        "@media(max-width: 1300px)": {
                          px: 1.5,
                        },
                        "@media(max-width: 1200px)": {
                          px: 1,
                        },
                        "@media(max-width: 1100px)": {
                          px: 0.5,
                        },
                      }}
                    >
                      {t(page.name)}
                    </Button>
                  ))}
                </Box>
              ) : null}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}

export default Header;