import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import { useIsVisible } from "react-is-visible";
import { useTranslation } from "react-i18next";
import { getContent } from "../../utils";

function Rules(content) {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setData(content.content || []);
  }, [content]);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setIsActive(isVisible)
    }
  }, [isActive, isVisible]);

  return (
    <Container
      maxWidth="none"
      id="rules"
      ref={nodeRef}
      sx={{
        padding: "2em !important",
        my: 4,
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: "#000000",
      }}
    >
      <Container
        maxWidth="none"
        sx={{
          mt: "auto",
          maxWidth: "1200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 !important",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            mb: 5
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: 24, md: 36 },
              fontWeight: 700,
              color: 'white',
              my: { xs: ".5em", md: 0 },
              textAlign: { xs: "center", md: "inherit" }
            }}
          >
            {t('minecraft.rules')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            gap: 2,
          }}
        >
          {(data || []).map((r, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flex: { xs: '1 1 100%', md: '1 1 48%'},
                width: '100%',
                minWidth: 200,
                minHeight: 50,
                my: 1,
                boxSizing: 'border-box'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                  minWidth: 50,
                  height: '100%',
                  backgroundColor: '#34C542',
                  mr: 2,
                }}
              >
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    fontSize: { xs: 20, md: 24 },
                    fontWeight: 700,
                    color: 'white',
                  }}
                >
                  {i + 1}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  px: 3,
                  backgroundColor: '#2B2B367A',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    fontSize: { xs: 12, md: 14 },
                    fontWeight: 400,
                    color: 'white',
                  }}
                >
                  {getContent(r, 'text', language)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Container>
  );
}

export default Rules;
